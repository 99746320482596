<template>
  <div class="receipts-list-filter mt-6">
    <!-- <p>
      {{ digital ? $t("receipts.findTextDigital") : $t("receipts.findText") }}
    </p> -->
    <v-form
      ref="formRef"
      v-model="formModel"
      lazy-validation
      @submit.prevent.stop="handleFormSubmit"
    >
      <v-row class="px-0">
        <v-col cols="12" sm="6" :lg="digital ? '5' : '3'">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateBegin"
                :label="$t('receipts.fromDate')"
                :id="'id_' + Math.random()"
                v-mask="'##/##/####'"
                required
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-bind="attrs" v-on="on" slot="append">
                  $editcalendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              v-model="filterData.dateBegin"
              :id="'id_' + Math.random()"
              :max="new Date() | dayjs('format', 'YYYY-MM-DD')"
              :min="minDate | dayjs('format', 'YYYY-MM-DD')"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" :lg="digital ? '5' : '3'">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEnd"
                :label="$t('receipts.toDate')"
                :id="'id_' + Math.random()"
                v-mask="'##/##/####'"
                required
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-bind="attrs" v-on="on" slot="append">
                  $editcalendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              v-model="filterData.dateEnd"
              :id="'id_' + Math.random()"
              :min="minDate | dayjs('format', 'YYYY-MM-DD')"
              :max="new Date() | dayjs('format', 'YYYY-MM-DD')"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="9" lg="4" v-if="!digital">
          <v-select
            :label="$t('receipts.receiptSelector')"
            v-model="filterData.orderTypeSelect"
            :items="filterData.orderTypeSelectItems"
            :rules="requiredRules"
            required
            dense
            hide-details
            outlined
            item-text="label"
            item-value="value"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="3" lg="2">
          <v-btn
            block
            :loading="searchReceiptLoading"
            type="submit"
            color="primary"
            depressed
            height="40"
          >
            {{ $t("orders.ordersFilter.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { requiredValue, isNumber } from "~/validator/validationRules";

import { mask } from "vue-the-mask";
export default {
  name: "ReceiptsListFilter",
  props: {
    digital: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      required: false
    }
  },
  data() {
    return {
      searchReceiptLoading: false,
      formModel: false,
      panelOpen: 0,
      menu: false,
      menu2: false,
      filterData: {
        dateBegin: null,
        dateEnd: null,
        orderTypeSelect: null,
        orderTypeSelectItems: [
          {
            label: "- Garanzie",
            value: "garanzie"
          },
          {
            label: "- Bollette",
            value: "bollette"
          }
        ]
      },
      isNumRules: [isNumber()],
      requiredRules: [requiredValue()]
    };
  },
  directives: { mask },
  computed: {
    dateBegin: {
      get: function() {
        if (this.filterData.dateBegin) {
          return this.$dayjs(this.filterData.dateBegin).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.filterData.dateBegin = this.$dayjs(value).format("DD/MM/YYYY");
      }
    },
    dateEnd: {
      get: function() {
        if (this.filterData.dateEnd) {
          return this.$dayjs(this.filterData.dateEnd).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.filterData.dateEnd = this.$dayjs(value).format("DD/MM/YYYY");
      }
    }
  },
  methods: {
    handleFormSubmit() {
      this.$emit("updateReceiptsFilter", this.filterData);
    }
  },
  mounted() {
    this.filterData.dateBegin = this.$dayjs(this.minDate).format("YYYY-MM-DD");
    this.filterData.dateEnd = this.$dayjs().format("YYYY-MM-DD");
    this.filterData.orderTypeSelect = this.filterData.orderTypeSelectItems[0].value;
    this.handleFormSubmit();
  }
};
</script>
